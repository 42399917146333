<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!--              <b-form-input-->
              <!--                v-model="searchKey"-->
              <!--                class="d-inline-block mr-1"-->
              <!--                placeholder="搜索关键字..."-->
              <!--              />-->
              <b-button
                  variant="primary"
                  @click="showStatement"
                  v-if="readonly===0"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-tabs>
        <b-tab title="结算清单">
          <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="items"
          >

            <template v-slot:custom-foot="data">
              <b-tr>
                <b-td :colspan="4" variant="primary">
                  <span><strong>合计</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(itemSum.ext)?'':itemSum.ext.receivableTotal}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(itemSum.ext)?'':itemSum.ext.receivedTotal}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(itemSum.ext)?'':itemSum.ext.remainTotal}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(itemSum.ext)?'':itemSum.ext.receiveCurrent}}</strong></span>
                </b-td>
                <b-td :colspan="3" variant="primary">
                  <span><strong>{{isEmpty(itemSum.ext)?'':itemSum.ext.arrears}}</strong></span>
                </b-td>
              </b-tr>
            </template>

            <!-- Columns -->
            <template #cell(id)="data">
              #{{ data.item.id }}
            </template>

            <template #row-details="row">
              <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
              <b-card>
                <div>
                  <b-table striped hover :items="row.item.ext.statementItem" :fields="fields">

                    <!--                <template v-slot:head(#)="data">-->
                    <!--                  <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">-->
                    <!--                  </b-form-checkbox>-->
                    <!--                </template>-->
                    <!--                <template v-slot:cell(#)="row">-->
                    <!--                  <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">-->
                    <!--                  </b-form-checkbox>-->
                    <!--                </template>-->
                    <template #cell(#)="data">
                      <b-form-checkbox
                          name="user-id"
                          v-model="data.item.is_check"
                          value="1"
                          unchecked-value="0"
                          @change="saveSign(data.item)"
                      >
                      </b-form-checkbox>
                    </template>

                    <template #cell(creator)="data">
                      {{ isEmpty(data.item.ext)?'':getCodeLabel("user",data.item.ext.creator) }}
                    </template>

                    <template #cell(sales_time)="data">
                      {{ toDate(data.item.sales_time) }}
                    </template>

                    <template #cell(order_no)="data">
                      <b-link
                          :to="{ name: (data.item.order_no.includes('SO')?((data.item.order_no.includes('OFSO')||data.item.order_no.includes('AFSO'))?'apps-offlinesalesorder-edit':(data.item.order_no.includes('QZSO')?'apps-presalesorder-list':'apps-salesorder-edit')):'apps-salesreturnbound-edit'), query: { id: data.item.order_id,orderNo:(data.item.order_no.includes('OFSO')||data.item.order_no.includes('AFSO'))?null:data.item.order_no },params:{readonly:1} }"
                          target="_blank"
                      >
                        {{ data.item.order_no }}
                      </b-link>

                    </template>

                  </b-table>
                </div>
              </b-card>
            </template>

            <!-- Column: Type -->
            <template #cell(statement_no)="data">
              <b-form-checkbox v-model="data.detailsShowing"
                               name="check-button"
                               @change="data.toggleDetails"
                               button-variant="default"
                               size="sm"
                               button>
                <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
                />
                <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
                />
              </b-form-checkbox>
              #{{ data.item.ext.statement.statement_no }}
            </template>

            <template #cell(channel_name)="data">
              {{ data.item.ext.statement.channel_name }}
            </template>

            <template #cell(receivable_total)="data">
              {{ data.item.ext.statement.receivable_total }}
            </template>

            <template #cell(received_tatal)="data">
              {{ isEmpty(data.item.ext.statement.received_tatal)?0:data.item.ext.statement.received_tatal }}(审核中：{{data.item.ext.reviewPrice}})
            </template>

            <template #cell(other_cost)="data">
              {{ data.item.ext.statement.other_cost }}
            </template>

            <template #cell(remain_total)="data">
              {{ data.item.ext.statement.remain_total }}
            </template>

            <template #cell(add_time)="data">
              {{ toDate(data.item.ext.statement.add_time) }}
            </template>

            <template #cell(arrears)="data">
              {{ data.item.ext.arrears }}
            </template>

            <template #cell(relational_no)="data">
              {{ data.item.ext.statement.relational_no }}
            </template>

            <template #cell(receive_current)="data">
              <b-form-input type="number"
                            v-model="data.item.receive_current"
                            style="width: 75px"
                            @change="change(data.item)"
                            size="sm"
                            :readonly="readonly!==0"
              >
              </b-form-input>
            </template>

            <!-- Column: State -->
            <template #cell(state)="data">
              <b-link
                  @click="changeState(data.item)"
              >
                <b-badge
                    pill
                    :variant="`light-$ {getCodeColor('state', data.item.state)}`"

                >
                  {{ getCodeLabel('state', data.item.state) }}
                </b-badge>
              </b-link>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">

              <b-link>
                <feather-icon @click="delOrderItem(data.item)"
                              icon="XSquareIcon"
                              size="16"
                              class="align-middle text-body"
                />
              </b-link>

            </template>

          </b-table>
        </b-tab>

        <b-tab title="商品清单">
          <b-table
              ref="refProductListTable"
              class="position-relative"
              :items="productList"
              responsive
              hover
              small
              :fields="productTableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
          >

            <template v-slot:custom-foot="data">
              <b-tr>
                <b-td :colspan="4" variant="primary">
                  <span><strong>合计</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(sum.ext)?'':sum.ext.sumSoQty}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(sum.ext)?'':sum.ext.sumSoSubtotal}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(sum.ext)?'':sum.ext.sumSrQty}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(sum.ext)?'':sum.ext.sumSrSubtotal}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(sum.ext)?'':sum.ext.sumAllQty}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{isEmpty(sum.ext)?'':sum.ext.sumAllSubtotal}}</strong></span>
                </b-td>
              </b-tr>
            </template>
            <!-- Columns -->
            <template #cell(warehouse)="data">
              {{ getCodeLabel("warehouse",data.item.ext.warehouse) }}
            </template>

            <template #cell(code)="data">
              {{ data.item.ext.code }}
            </template>

            <template #cell(name)="data">
              {{ data.item.ext.name }}
            </template>

            <template #cell(cost)="data">
              {{ data.item.ext.cost }}
            </template>

            <template #cell(soQty)="data">
              {{ data.item.ext.soQty }}
            </template>

            <template #cell(soSubtotal)="data">
              {{ data.item.ext.soSubtotal }}
            </template>

            <template #cell(srQty)="data">
              {{ data.item.ext.srQty }}
            </template>

            <template #cell(srSubtotal)="data">
              {{ data.item.ext.srSubtotal }}
            </template>

            <template #cell(qty)="data">
              {{ data.item.ext.qty }}
            </template>

            <template #cell(Subtotal)="data">
              {{ data.item.ext.subtotal }}
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              sm="6"-->
          <!--              class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
          <!--          >-->

          <!--            <b-pagination-->
          <!--                v-model="start"-->
          <!--                :total-rows="listTotals"-->
          <!--                :per-page="limit"-->
          <!--                first-number-->
          <!--                last-number-->
          <!--                class="mb-0 mt-1 mt-sm-0"-->
          <!--                prev-class="prev-item"-->
          <!--                next-class="next-item"-->
          <!--            >-->
          <!--              <template #prev-text>-->
          <!--                <feather-icon-->
          <!--                    icon="ChevronLeftIcon"-->
          <!--                    size="18"-->
          <!--                />-->
          <!--              </template>-->
          <!--              <template #next-text>-->
          <!--                <feather-icon-->
          <!--                    icon="ChevronRightIcon"-->
          <!--                    size="18"-->
          <!--                />-->
          <!--              </template>-->
          <!--            </b-pagination>-->

          <!--          </b-col>-->

        </b-row>
      </div>

    </b-card>

    <b-modal
        id="statementSelect"
        ok-only
        ok-title="确认"
        @ok="checkStatementSelect"
        cancel-title="取消"
        centered
        size="lg"
        title="对账单列表"
        ref="statementSelect"
    >
      <statement-list
          ref="statementSelects" :channelId="channelId" :warehouseId="warehouseId" :storeId="storeId" :type="type" :receiveId="receiveId">
      </statement-list>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTabs,BTab
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import statementreceiveitemUseList from './statementreceiveitemUseList'
import statementreceiveitemStore from './statementreceiveitemStore'
import StatementList from "@/views/apps/statement/modal/StatementList";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BTabs,
    BTab,
    StatementList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    receiveId: {
      type: Number,
      default: 0,
    },
    channelId: {
      type: Number,
      default: 0,
    },
    warehouseId: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
    storeId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {key: '#', label: '标记'},
        {key: 'item_id', label: '序号'},
        {key: 'order_no', label: '编号'},
        {key: 'creator', label: '制单人'},
        {key: 'sales_name', label: '业务员'},
        {key: 'total_qty', label: '销售数量'},
        {key: 'total_amount', label: '销售金额'},
        {key: 'sales_time', label: '销售日期'},
      ],
      checked: [],
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      item: [],
      items: [],
    }
  },
  methods: {
    // 点击全选按钮
    toggleAll(checked) {
      this.checked = []
      this.table.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(id) {
      let index = this.checked.indexOf(id);
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      this.$forceUpdate()
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statementreceiveitem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    getItem() {
      const ids = []
      const icChecks = []
      const receivableTotal = []
      const receiveCurrent = []
      const remainTotal = []
      const id = []
      for (let i = 0; i < this.items.length; i++) {
        for (let j = 0; j < this.items[i].ext.statementItem.length; j++) {
          ids.push(this.items[i].ext.statementItem[j].item_id)
          if (this.items[i].ext.statementItem[j].is_check==null){
            this.items[i].ext.statementItem[j].is_check=0
          }
          icChecks.push(this.items[i].ext.statementItem[j].is_check)
        }
        receivableTotal.push(this.items[i].ext.statement.receivable_total)
        remainTotal.push(this.items[i].ext.statement.remain_total)
        receiveCurrent.push(parseFloat(this.items[i].receive_current))
        id.push(this.items[i].item_id)
        console.log(i,this.items[i].receive_current)
      }
      console.log("receiveCurrent",receiveCurrent)
      const array = []
      array.push(ids.join(","))
      array.push(icChecks.join(","))
      array.push(receivableTotal)
      array.push(receiveCurrent)
      array.push(id)
      array.push(remainTotal)
      return array
    }
  },
  mounted() {
    this.isCheckedObj = {};
    this.table.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
    });
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        if (newVal.length == 0) {
          this.indeterminate = false
          this.allchecked = false
        } else if (newVal.length == this.table.length) {
          this.indeterminate = false
          this.allchecked = true
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    }
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('statementreceiveitem')) store.registerModule('statementreceiveitem', statementreceiveitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementreceiveitem')) store.unregisterModule('statementreceiveitem')
    })

    const showStatement = function () {
      if (this.channelId == 0) {
        toast.error("请选择付款单位!")
        return false
      }
      // if (this.type == 2 && this.warehouseId == 0) {
      //   toast.error("请选择仓库!")
      //   return false
      // }
      if (this.storeId == 0) {
        toast.error("请选择店铺!")
        return false
      }
      this.$refs['statementSelect'].show()
    }

    const checkStatementSelect = function () {
      const check = this.$refs.statementSelects.getChecked()
      if (check.length == 0) {
        toast.error("对账单不能为空!")
        return false
      }
      const stId = []
      for (let i = 0; i < check.length; i++) {
        stId.push(check[i].statement_id)
      }
      const stIds = stId.join(",")
      store.dispatch('statementreceiveitem/add', {receiveId: this.receiveId, stIds: stIds}).then(res => {
        if (res.data.code == 0) {
          toast.success('增加成功!')
          // refetchData()
          this.$emit("table", "success")
        } else {
          toast.error(res.data.data)
        }
      })

    }

    const delOrderItem = function (params) {
      if (confirm("确定删除吗")) {
        store.dispatch('statementreceiveitem/del', {id: params.item_id}).then(res => {
          if (res.data.code == 0) {
            toast.success('删除成功!')
            // refetchData()
            this.$emit("table", "success")
          } else {
            toast.error('删除失败!')
          }
        })
      }
    }

    const change = function (params) {
      params.ext.arrears = (params.ext.statement.remain_total - params.receive_current).toFixed(2)
      store.dispatch('statementreceiveitem/save', params).then(res => {
        if (res.data.code == 0) {
          toast.success('修改成功!')
          //解决数组数据不刷新
          for (let i = 0; i < this.items.length; i++) {
              if (params.item_id === this.items[i].item_id){
                this.items[i].receive_current = params.receive_current
              }
          }
          // refetchData()
          this.$emit("table", "success")
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const saveSign = function (params) {
      store.dispatch('statementreceiveitem/saveSign', {statementItemId:params.item_id,sign:params.is_check})
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      productList,
      productTableColumns,
      sum,
      itemSum,

      // UI
    } = statementreceiveitemUseList({
      receiveId: props.receiveId,
      readonly: props.readonly
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      productList,
      productTableColumns,
      sum,
      itemSum,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      showStatement,
      checkStatementSelect,
      delOrderItem,
      isEmpty,
      change,
      saveSign,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
