<template>
  <b-card
      class="statementreceive-edit-wrapper"
  >
    <!-- form -->
    <b-form id="statementreceiveForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">付款信息</div>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="收款编号"
              label-for="receive_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="receive_no"
                size="sm"
                v-model="statementreceive.receive_no"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="结算单位"
              label-for="channel_name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="channel_name"
                size="sm"
                v-model="statementreceive.channel_name"
                @click="showSingleModal(type==2?'终端客户':'渠道方')"
                placeholder="点击搜索付款单位"
                readonly
                :disabled="isnew"
            />
          </b-form-group>
        </b-col>

        <!--        <b-col md="3">-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="付款方式"-->
        <!--              label-for="pay_method"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1 required"-->
        <!--          >-->
        <!--            <v-select-->
        <!--                id="pay_method"-->
        <!--                :options="getCodeOptions('statement_pay_method')"-->
        <!--                :clearable="true"-->
        <!--                v-model="payMethod"-->
        <!--                @input="changeSelect('pay_method',$event)"-->
        <!--                class="select-size-sm"-->
        <!--                placeholder="请选择付款方式"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!--        <b-col md="3" v-if="showPayAccount">-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="付款账号"-->
        <!--              label-for="pay_account"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--                id="pay_account"-->
        <!--                size="sm"-->
        <!--                v-model="statementreceive.pay_account"-->
        <!--                @click="showSingleModal('付款银行账号')"-->
        <!--                placeholder="点击搜索付款银行账号"-->
        <!--                readonly-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="主体名称"
              label-for="company_name"
              label-size="sm"
              class="required"
          >
            <b-form-input
                id="company_name"
                size="sm"
                v-model="statementreceive.company_name"
                @click="showSingleModal('主体')"
                placeholder="点击搜索主体"
                readonly
                :disabled="srmReadonly!==0&&type!=1"
            />
          </b-form-group>
        </b-col>
        <!--        <b-col md="3">-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="收款账号"-->
        <!--              label-for="receive_account"-->
        <!--              label-size="sm"-->
        <!--              :class="'mb-1 '+required"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--                id="receive_account"-->
        <!--                size="sm"-->
        <!--                v-model="statementreceive.receive_account"-->
        <!--                @click="showSingleModal('银行账号')"-->
        <!--                placeholder="点击搜索银行账号"-->
        <!--                readonly-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
<!--        <b-col md="3" v-if="type==2">-->
<!--          <b-form-group-->
<!--              label-cols="3"-->
<!--              label-cols-lg="3"-->
<!--              label="仓库名称"-->
<!--              label-for="warehouse_name"-->
<!--              label-size="sm"-->
<!--              :class="'mb-1 '+ (type==2?'required':'')"-->
<!--          >-->
<!--            <b-form-input-->
<!--                id="warehouse_name"-->
<!--                size="sm"-->
<!--                v-model="statementreceive.warehouse_name"-->
<!--                @click="showSingleModal('仓库')"-->
<!--                placeholder="点击搜索仓库"-->
<!--                readonly-->
<!--                :disabled="isnew"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col md="3" >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="店铺名称"
              label-for="store_name"
              label-size="sm"
              :class="'mb-1 '+ (type==1?'required':'')"
          >
            <b-form-input
                id="store_name"
                size="sm"
                v-model="statementreceive.store_name"
                @click="showSingleModal('店铺')"
                placeholder="点击搜索店铺"
                readonly
                :disabled="isnew||(srmReadonly!==0&&type!=1)"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="收款人"
              label-for="receive_user"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="receive_user"
                size="sm"
                v-model="receiveUser"
                @click="showSingleModal('收款人')"
                placeholder="点击搜索收款人"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="收款日期"
              label-for="reach_time"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                v-model="statementreceive.reach_time"
                size="sm"
                class="form-control"
                placeholder="请选择到账日期"
                :disabled="statementreceive.source==2&&srmReadonly!==0&&type!=1"
                :config="{maxDate: 'today'}"
            />
          </b-form-group>
        </b-col>
        <b-col md="6"></b-col>
        <!--        <b-col md="3" >-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="收款金额"-->
        <!--              label-for="receive_total"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1 required"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--                id="receive_total"-->
        <!--                size="sm"-->
        <!--                v-model="statementreceive.receive_total"-->
        <!--                type="number"-->
        <!--                @change="changeReceiveTotal"-->
        <!--                :readonly="readonly===0?false:true"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="应收余额"
              label-for="receive_total"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="remainTotal==undefined?remainTotal:remainTotal.toFixed(2)"
                readonly
            />
          </b-form-group>
        </b-col>

<!--        <b-col md="3">-->
<!--          <b-form-group-->
<!--              label-cols="3"-->
<!--              label-cols-lg="3"-->
<!--              label="预收金额"-->
<!--              label-for="receive_total"-->
<!--              label-size="sm"-->
<!--              class="mb-1"-->
<!--          >-->
<!--            <b-form-input-->
<!--                size="sm"-->
<!--                v-model="surplusReceiveTotal==undefined?surplusReceiveTotal:surplusReceiveTotal.toFixed(2)"-->
<!--                readonly-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->

        <!--        <b-col md="3" >-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="其他费用合计"-->
        <!--              label-for="receive_total"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--                size="sm"-->
        <!--                v-model="otherCost"-->
        <!--                readonly-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="总应收款"
              label-for="receive_total"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="receivableTotal==undefined?receivableTotal:receivableTotal.toFixed(2)"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">收款明细</div>
        </b-col>

        <b-col md="7">
          <div>
            <statement-receive-method-list
                ref="statementReceiveMethod"
                :receiveId="statementreceive.receive_id"
                :bankList="bankList"
                :statementreceive="statementreceive"
                :sumTotal="remainTotal - allReceiveTotal - allAmount"
                v-on:table="itemTable($event,1)"
                v-on:refetchData="toRefetch"
                v-if="itemListShowFlag"
                :readonly="readonly"
                :type="type"
                :srmReadonly="srmReadonly"
            >
            </statement-receive-method-list>
          </div>
        </b-col>

        <b-col md="5" >
          <statement-cost-list
              ref="otherCostList"
              :statementId="statementreceive.receive_id"
              :statementreceive="statementreceive"
              :sumTotal="remainTotal - allReceiveTotal - allAmount"
              v-on:table="itemTable($event,2)"
              v-on:refetchData="toRefetch"
              v-if="itemListShowFlag"
              :readonly="readonly"
              :type="type"
          >
          </statement-cost-list>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title mt-2 text-danger">合计收款金额：￥{{(allReceiveTotal+allAmount).toFixed(6) }}</div>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">关联对账</div>
        </b-col>

        <b-col md="12">
          <statement-receive-item-list
              ref="statementReceiveItem"
              :receiveId="statementreceive.receive_id"
              :channelId="statementreceive.channel_id"
              :storeId="statementreceive.store_id"
              :type="parseInt(type)"
              v-on:table="fromChildren"
              v-if="itemListShowFlag"
              :readonly="readonly"
          >
          </statement-receive-item-list>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">其他信息</div>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="1"
              label="备注"
              label-for="memo"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="statementreceive.memo"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="statementreceive.loaded" :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="statementreceive.attachments"
                               :object_type="'statement_receive'"
                               :object_id="statementreceive.receive_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="success"
              class="mr-1"
              @click="save"
              v-if="readonly===0"
          >
            保存
          </b-button>
          <b-button
              class="mr-1"
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
          <b-button
              variant="primary"
              class="mr-1"
              @click="submit"
              v-if="readonly===0"
          >
            提交
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName === '主体'|| modalName ==='终端客户' || modalName ==='渠道方' "
      >
      </company-select>

      <warehouse-list
          ref="warehouseSelect" :storeIds="statementreceive.store_id" v-else-if="modalName === '仓库'"
      >
      </warehouse-list>

      <bank-account-list
          ref="bankSelect"
          v-else-if="modalName === '银行账号'"
          :companyId="statementreceive.company_id"
      >
      </bank-account-list>

      <bank-account-list
          ref="payBankSelect"
          v-else-if="modalName === '付款银行账号'"
          :companyId="statementreceive.channel_id"
      >
      </bank-account-list>

      <user-select
          ref="userSelect" v-if="modalName === '收款人'"
      >
      </user-select>

      <store-list
          ref="storeList" v-if=" modalName === '店铺'"
      >
      </store-list>

    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import statementreceiveStore from './statementreceiveStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second, isEmpty} from '@core/utils/filter'
import StatementReceiveItemList from "@/views/apps/statementreceiveitem/StatementReceiveItemList";
import CompanySelect from "@/views/apps/company/CompanySelect"
import WarehouseList from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
import flatPickr from 'vue-flatpickr-component'
import BankAccountList from "@/views/apps/bankaccount/bankaccount-modal/BankAccountList";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import statementreceiveitemStore from "@/views/apps/statementreceiveitem/statementreceiveitemStore";
import axios from "@/libs/axios";
import StatementReceiveMethodList from "@/views/apps/statementreceivemethod/StatementReceiveMethodList";
import StatementCostList from "@/views/apps/statementcost/StatementCostList";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import {useRouter} from "@core/utils/utils";
import StoreList from "@/views/apps/store/store-modal/StoreListSelect";

export default {
  components: {
    StoreList,
    UserSelect,
    StatementCostList,
    StatementReceiveMethodList,
    BankAccountList,
    StatementReceiveItemList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    CompanySelect,
    WarehouseList,
    flatPickr,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      statementreceive: ref({}),
      companyType: '',
      modalName: '',
      itemListShowFlag: false,
      payMethod: {},
      remainTotal: 0,
      isnew: false,
      showPayAccount: true,
      surplusReceiveTotal: 0,
      otherCost: 0,
      receivableTotal: 0,
      readonly: 0,
      required: 'required',
      bankList: [],
      receiveUser: '',
      allPrice: 0,
      allReceiveTotal: 0,
      allAmount: 0,
      type:0,
      routeName:'',
      srmReadonly:0,
    }
  },
  methods: {
    showSingleModal(modalName) {
      this.modalName = modalName
      if (modalName === '主体') {
        this.companyType = 'Group'
      }
      if (modalName === '终端客户') {
        this.companyType = 'OffSalesChannelCustomer'
        this.itemListShowFlag = false
        this.$nextTick(() => {
          this.itemListShowFlag = true
        })
      }
      if (modalName === '渠道方') {
        this.companyType = 'ChannelCustomer'
        this.itemListShowFlag = false
        this.$nextTick(() => {
          this.itemListShowFlag = true
        })
      }
      if (modalName === '仓库') {
        this.companyType = 'OffSalesChannelCustomer'
        this.itemListShowFlag = false
        this.$nextTick(() => {
          this.itemListShowFlag = true
        })
      }
      this.$refs['singleModal'].show()
    },
    accAdd(arg1,arg2) {
      let r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m;
    },
    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '终端客户': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.statementreceive.channel_name = data[0].company_name;
            this.statementreceive.channel_id = data[0].company_id;
            this.$forceUpdate()
            store.dispatch('statementreceiveitem/dels', {
              id: this.statementreceive.receive_id,
            }).then(res => {
              if (res.data.code == 0) {
                this.$refs['statementReceiveItem'].refetchData()
              }
            })
          }
          break
        }
        case '渠道方': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.statementreceive.channel_name = data[0].company_name;
            this.statementreceive.channel_id = data[0].company_id;
            this.$forceUpdate()
            store.dispatch('statementreceiveitem/dels', {
              id: this.statementreceive.receive_id,
            }).then(res => {
              if (res.data.code == 0) {
                this.$refs['statementReceiveItem'].refetchData()
              }
            })
          }
          break
        }
        case '主体': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.statementreceive.company_name = data[0].company_name;
            this.statementreceive.company_id = data[0].company_id;
            this.statementreceive.receive_account = ''
            this.searchBank()
            this.$forceUpdate()
          }
          break
        }
        case '仓库': {
          data = this.$refs.warehouseSelect.getSelected()
          if (data.length > 0) {
            this.statementreceive.warehouse_name = data[0].warehouse_name;
            this.statementreceive.warehouse_id = data[0].warehouse_id;
            this.$forceUpdate()
            store.dispatch('statementreceiveitem/dels', {
              id: this.statementreceive.receive_id,
            }).then(res => {
              if (res.data.code == 0) {
                this.itemListShowFlag = false
                this.$nextTick(() => {
                  this.itemListShowFlag = true
                })
                this.$refs['statementReceiveItem'].refetchData()
              }
            })
          }
          break;
        }
        case '店铺': {
          data = this.$refs.storeList.getSelected()
          if (data.length > 0) {
            this.statementreceive.store_name = data[0].store_name;
            this.statementreceive.store_id = data[0].store_id;
            this.$forceUpdate()
            store.dispatch('statementreceiveitem/dels', {
              id: this.statementreceive.receive_id,
            }).then(res => {
              if (res.data.code == 0) {
                this.itemListShowFlag = false
                this.$nextTick(() => {
                  this.itemListShowFlag = true
                })
                this.$refs['statementReceiveItem'].refetchData()
              }
            })
          }
          break;
        }
        // case '银行账号': {
        //   data = this.$refs.bankSelect.getSelected()
        //   if (data.length > 0) {
        //     this.statementreceive.receive_account = data[0].account_no;
        //     this.$forceUpdate()
        //   }
        //   break;
        // }
        // case '付款银行账号': {
        //   data = this.$refs.payBankSelect.getSelected()
        //   if (data.length > 0) {
        //     this.statementreceive.pay_account = data[0].account_no;
        //     this.$forceUpdate()
        //   }
        //   break;
        // }
        case '收款人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.receiveUser = data[0].full_name;
            this.statementreceive.receive_user = data[0].user_id;
            this.$forceUpdate()
            this.$emit('change', this.data)
          }
          break;
        }
        default: {
          break
        }
      }
    },
    setMainCompanyName() {
      this.statementreceive.account_no = ''
      this.accountName = ''
      axios.post('/api/bankaccount/search', {
        company_id: this.statementreceive.company_id
      }).then(res => {
        if (res.data.data.list.length == 1) {
          this.statementreceive.account_no = res.data.data.list[0].account_no
        }
      })

    }
  },
  setup() {
    const toast = useToast()
    const router = useRouter()

    // Register module
    if (!store.hasModule('statementreceive')) store.registerModule('statementreceive', statementreceiveStore)
    if (!store.hasModule('statementreceiveitem')) store.registerModule('statementreceiveitem', statementreceiveitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementreceive')) store.unregisterModule('statementreceive')
      if (store.hasModule('statementreceiveitem')) store.unregisterModule('statementreceiveitem')
    })

    const edit = function () {
      store.dispatch('statementreceive/edit', {id: this.id}).then(res => {
        this.statementreceive = res.data.data
        this.searchBank()
        this.payMethod = getCode("statement_pay_method", this.statementreceive.pay_method)
        this.receiveUser = getCodeLabel("user", this.statementreceive.receive_user)
        this.remainTotal = res.data.data.ext.remainTotal
        this.surplusReceiveTotal = res.data.data.ext.receiveTotal
        this.otherCost = res.data.data.ext.otherCost
        this.receivableTotal = res.data.data.ext.receivableTotal
        this.isnew = this.statementreceive.ext.isnew
        this.srmReadonly == 1?this.isnew = false:this.isnew = this.isnew
        this.statementreceive.reach_time = toTime(this.statementreceive.reach_time)
        if (this.statementreceive.pay_method != null && this.statementreceive.pay_method != 2 && this.statementreceive.pay_method != 3) {
          this.statementreceive.pay_account = ''
          this.showPayAccount = false
        }
        if (this.statementreceive.pay_method != null && this.statementreceive.pay_method == 1) {
          this.required = ''
        }

      })
    }

    const view = function () {
      store.dispatch('statementreceive/view', {id: this.id}).then(res => {
        this.statementreceive = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.sign != 1) {
        if (this.remainTotal>=0&&this.surplusReceiveTotal < 0) {
          toast.error("收款金额大于应收金额,无法保存!")
          return false
        }
        // if (this.remainTotal<=0&&this.surplusReceiveTotal > 0) {
        //   toast.error("收款金额小于应收金额,无法保存!")
        //   return false
        // }
      }
      if (this.statementreceive.channel_id === undefined || this.statementreceive.channel_id === "") {
        toast.error("请选择 结算单位!")
        return false
      }
      if (this.statementreceive.company_id === undefined || this.statementreceive.company_id === "") {
        toast.error("请选择 主体名称!")
        return false
      }
      // if (this.type==2 && (this.statementreceive.warehouse_id === undefined || this.statementreceive.warehouse_id === "")) {
      //   toast.error("请选择 仓库!")
      //   return false
      // }
      if (isEmpty(this.statementreceive.store_id)) {
        toast.error("请选择 店铺!")
        return false
      }
      if (this.statementreceive.reach_time === undefined || this.statementreceive.reach_time === "") {
        toast.error("请选择 收款日期!")
        return false
      }
      if (this.remainTotal>=0&&this.accAdd(this.allReceiveTotal + this.allAmount) > this.remainTotal) {
        toast.error("本次收款不能大于应收余额!")
        return false
      }
      if (this.remainTotal<=0&&this.accAdd(this.allReceiveTotal + this.allAmount) < this.remainTotal) {
        toast.error("本次收款不能小额!")
        return false
      }
      // if (this.remainTotal>=0&&this.accAdd(this.allReceiveTotal + this.allAmount) < 0) {
      //   toast.error("收款金额小于应收金额,无法保存!")
      //   return false
      // }
      if (this.remainTotal<=0&&this.accAdd(this.allReceiveTotal + this.allAmount) > 0) {
        toast.error("收款金额大于应收金额,无法保存!")
        return false
      }


      const item = this.$refs['statementReceiveItem'].getItem()
      this.statementreceive.ids = item[0]
      this.statementreceive.isChecks = item[1]
      let receiveCurrent = 0;
      for (let i = 0; i < item[2].length; i++) {
        if (item[2][i]>0){
            if (item[3][i]>item[5][i]){
              toast.error("id:"+item[4][i]+"本次收款大于应收金额,无法保存!")
              return false
            }
        }else if (item[2][i]<0){
          if (item[3][i]<item[5][i]){
            toast.error("id:"+item[4][i]+" 本次收款小于应收金额,无法保存!")
            return false
          }
        }else {
          if (item[3][i]!=0){
            toast.error("id:"+item[4][i]+" 本次收款不等于0,无法保存!")
            return false
          }
        }
        receiveCurrent=this.accAdd(receiveCurrent,parseFloat(item[3][i]))
      }
      // console.log(receiveCurrent.toFixed(6))
      // console.log((this.allReceiveTotal+this.allAmount).toFixed(6))
      //2022-10-28 - 保存去除限制
      // if (receiveCurrent.toFixed(6)!=(this.allReceiveTotal+this.allAmount).toFixed(6)){
      //   toast.error("本次收款总额和合计收款金额不相等，无法保存")
      //   return false
      // }
      this.statementreceive.sign = this.sign
      if (isEmpty(this.statementreceive.type)){
        this.statementreceive.type = router.route.value.query.type
      }
      this.statementreceive.receiveCurrents = item[3].join(",")
      this.statementreceive.itemIds = item[4].join(",")
      this.statementreceive.reach_time = second(this.statementreceive.reach_time)
      store.dispatch('statementreceive/save', this.statementreceive).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          // this.$router.push({name: this.routeName,query:{type:router.route.value.query.type}});
        } else {
          toast.error(res.data.data)
        }
      })
      this.statementreceive.reach_time = toTime(this.statementreceive.reach_time)

    }

    const changeSelect = function (key, event) {
      this.showPayAccount = true
      this.statementreceive[key] = event == null ? null : event.value
      if (key === 'pay_method') {
        if (event != null && event.value == 2 || event.value == 3) {
          this.statementreceive.pay_account = ''
          axios.post('/api/bankaccount/search', {
            company_id: this.statementreceive.company_id==undefined?0:this.statementreceive.company_id
          }).then(res => {
            // if (res.data.data.list.length == 1) {
            //   this.statementreceive.pay_account = res.data.data.list[0].account_no
            //   this.$forceUpdate()
            // }
          })
        }
        if (event != null && event.value != 2 && event.value != 3) {
          this.statementreceive.pay_account = ''
          this.showPayAccount = false
        }
        if (event != null && event.value == 1) {
          this.required = ''
        } else {
          this.required = 'required'
        }

      }
    }

    const onUploaded = function (id, attachment, result) {
      this.statementreceive[id] = result
    }

    const fromChildren = function (params) {
      this.statementreceive.receive_total = 0
      store.dispatch('statementreceive/calculation', {
        receiveId: this.statementreceive.receive_id,
        receiveTotal: this.statementreceive.receive_total
      }).then(res => {
        if (res.data.code == 0) {
          const data = res.data.data.ext
          this.remainTotal = data.remainTotal
          this.surplusReceiveTotal = data.receiveTotal
          this.otherCost = data.otherCost
          this.receivableTotal = data.receivableTotal
          store.dispatch('statementreceiveitem/changeReceiveTotal', {
            id: this.statementreceive.receive_id,
          }).then(res => {
            if (res.data.code == 0) {
              this.statementreceive.ext.remainTotal = res.data.data.ext.remainTotal
              this.$refs['statementReceiveItem'].refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const changeReceiveTotal = function (params) {
      if (this.statementreceive.receive_total > this.remainTotal) {
        if (this.sign != 1) {
          toast.error("收款金额应小于等于应收余额!")
          this.statementreceive.receive_total = this.remainTotal
        }
      }
      store.dispatch('statementreceiveitem/changeReceiveTotal', {
        id: this.statementreceive.receive_id,
        receiveTotal: this.statementreceive.receive_total
      }).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          this.$refs['statementReceiveItem'].refetchData()
          this.surplusReceiveTotal = this.remainTotal - this.statementreceive.receive_total
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const submit = function () {
      if (this.sign != 1) {
        if (this.remainTotal>=0&&this.surplusReceiveTotal < 0) {
          toast.error("收款金额大于应收金额,无法保存!")
          return false
        }
        // if (this.remainTotal<=0&&this.surplusReceiveTotal > 0) {
        //   toast.error("收款金额小于应收金额,无法保存!")
        //   return false
        // }
      }
      if (this.statementreceive.channel_id === undefined || this.statementreceive.channel_id === "") {
        toast.error("请选择 结算单位!")
        return false
      }
      if (this.statementreceive.company_id === undefined || this.statementreceive.company_id === "") {
        toast.error("请选择 主体名称!")
        return false
      }
      // if (this.type==2 && (this.statementreceive.warehouse_id === undefined || this.statementreceive.warehouse_id === "")) {
      //   toast.error("请选择 仓库!")
      //   return false
      // }
      if (isEmpty(this.statementreceive.store_id)) {
        toast.error("请选择 店铺!")
        return false
      }
      if (this.statementreceive.reach_time === undefined || this.statementreceive.reach_time === "") {
        toast.error("请选择 收款日期!")
        return false
      }
      if (this.remainTotal>=0&&this.accAdd(this.allReceiveTotal + this.allAmount) > this.remainTotal) {
        toast.error("本次收款不能大于应收余额!")
        return false
      }
      if (this.remainTotal<=0&&this.accAdd(this.allReceiveTotal + this.allAmount) < this.remainTotal) {
        toast.error("本次收款不能小额!")
        return false
      }
      // if (this.remainTotal>=0&&this.accAdd(this.allReceiveTotal + this.allAmount) < 0) {
      //   toast.error("收款金额小于应收金额,无法保存!")
      //   return false
      // }
      if (this.remainTotal<=0&&this.accAdd(this.allReceiveTotal + this.allAmount) > 0) {
        toast.error("收款金额大于应收金额,无法保存!")
        return false
      }


      const item = this.$refs['statementReceiveItem'].getItem()
      this.statementreceive.ids = item[0]
      this.statementreceive.isChecks = item[1]
      this.statementreceive.sign = this.sign
      let receiveCurrent = 0;
      for (let i = 0; i < item[2].length; i++) {
        if (item[2][i]>0){
          if (item[3][i]>item[5][i]){
            toast.error("id:"+item[4][i]+"本次收款大于应收金额,无法保存!")
            return false
          }
        }else if (item[2][i]<0){
          if (item[3][i]<item[5][i]){
            toast.error("id:"+item[4][i]+" 本次收款小于应收金额,无法保存!")
            return false
          }
        }else {
          if (item[3][i]!=0){
            toast.error("id:"+item[4][i]+" 本次收款不等于0,无法保存!")
            return false
          }
        }
        receiveCurrent=this.accAdd(receiveCurrent,parseFloat(item[3][i]))
      }
      // console.log(receiveCurrent.toFixed(6))
      // console.log(this.allReceiveTotal)
      // console.log(this.allAmount)
      // if (receiveCurrent.toFixed(6)!=(this.allReceiveTotal+this.allAmount).toFixed(6)){
      //   toast.error("本次收款总额和合计收款金额不相等，无法保存")
      //   return false
      // }
      this.statementreceive.sign = this.sign
      if (isEmpty(this.statementreceive.type)){
        this.statementreceive.type = router.route.value.query.type
      }
      this.statementreceive.receiveCurrents = item[3].join(",")
      this.statementreceive.itemIds = item[4].join(",")
      this.statementreceive.reach_time = second(this.statementreceive.reach_time)
      store.dispatch('statementreceive/save', this.statementreceive).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          if (confirm("是否提交审核?")) {
            store.dispatch('statementreceive/changeStatus', {
              id: this.statementreceive.receive_id,
              status: 2
            }).then(res => {
              if (res.data.code == 0) {
                toast.success("审核成功")
                this.$router.push({name: this.routeName,query:{type:router.route.value.query.type}});
              } else {
                toast.error(res.data.data)
              }
            })
          }
        } else {
          toast.error(res.data.data)
        }
      })
      this.statementreceive.reach_time = toTime(this.statementreceive.reach_time)

    }

    const itemTable = function (params, data) {
      if (data === 1) {
        this.allReceiveTotal = params
      }
      if (data === 2) {
        this.allAmount = params
      }
    }

    const toRefetch = function (params) {
      this.$refs['statementReceiveItem'].refetchData()
      this.surplusReceiveTotal = this.remainTotal - parseFloat(params)
    }

    const searchBank = function () {
      this.bankList.length = 0
      axios.post('/api/bankaccount/search', {
        company_id: this.statementreceive.company_id==undefined?0:this.statementreceive.company_id
      }).then(res => {
        const data = res.data.data.list
        for (let i = 0; i < data.length; i++) {
          this.bankList.push({"label": data[i].account_no, "value": data[i].account_id})
        }
        this.itemListShowFlag = false
        this.$nextTick(() => {
          this.itemListShowFlag = true
        })
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      changeSelect,
      onUploaded,
      second,
      toTime,
      fromChildren,
      changeReceiveTotal,
      submit,
      itemTable,
      searchBank,
      toRefetch,
    }
  },
  watch: {
    $route(to, from) {
      location.reload()
    },
    type:{
      handler(newValue,oldValue){
        this.routeName = newValue == 1 ?'apps-statementreceive-ol-list':'apps-statementreceive-list'
      }
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.readonly = this.$route.params.readonly || null;
    this.sign = this.$route.query.sign || 0;
    this.type = this.$route.query.type || 0;
    if (this.$route.params.readonly === 0 || this.id === 0) {
      this.readonly = 0
    }
    if (this.$route.query.readonlys == 0) {
      this.readonly = 0
    }
    if (this.$route.query.srmReadonly == 1) {
      this.srmReadonly = 1
    }
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
