import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function statementUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#'},
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'statement_no', label: '编号', sortable: true },
    // { key: 'channel_id', label: '终端客户ID', sortable: true },
    { key: 'channel_name', label: '终端客户', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'store_name', label: '店铺名称', sortable: true },
    { key: 'receivable_total', label: '应收金额', sortable: true },
    { key: 'received_tatal', label: '已收金额', sortable: true },
    // { key: 'other_cost', label: '其他费用', sortable: true },
    { key: 'remain_total', label: '应收余额', sortable: true },
    // { key: 'statement_over', label: '到账天数', sortable: true },
    // { key: 'statement_over_day', label: '预计到账日期', sortable: true },
    // { key: 'day', label: '账龄/天', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '结算日期', sortable: true },
    // { key: 'creator', label: '创建人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
    //     { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100,1000]
  const listTotals = ref(0)
  const searchKey = ref('')
  const statementNo = ref('')
  const addTime = ref('')
  const orderBy = ref('statement_id')
  const isSortDirDesc = ref(true)

  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, statementNo,addTime], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    // for (let i = 0; i < tableColumns.length; i++) {
    //   if (tableColumns[i].key=="warehouse_name"&&config.type==1){
    //     tableColumns.splice(i,1)
    //   }
    //   if (tableColumns[i].key=="store_name"&&config.type==2){
    //     tableColumns.splice(i,1)
    //   }
    // }
    store
      .dispatch('statement/search', {
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        channelId:config.channelId,
        // warehouseId:config.type==2?config.warehouseId:null,
        storeIds:config.storeId,
        type:config.type,
        receiveId:config.receiveId,
        remainTotalIsZero:0,
        addTime:addTime.value,
        statementNo:statementNo.value,
        statementStatus:2,
        all:1,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        for (let i = 0; i < list.length; i++) {
          list[i].isSelect = 0
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,
    statementNo,addTime,

    // Extra Filters
  }
}
