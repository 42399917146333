<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="3"
          >
            <b-form-input
                size="sm"
                v-model="statementNo"
                placeholder="请输入对账单编号"
            />
          </b-col>
          <b-col
              cols="12"
              md="3"
          >
            <flat-pickr
                class="form-control"
                :config="{ mode: 'range'}"
                v-model="addTime"
                placeholder="请输入结算日期"
            />
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.statementNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款结清"
                    label-for="qtyIsZero"
                    label-size="sm"
                >
                  <v-select
                      id="qtyIsZero"
                      :options="getCodeOptions('yesno')"
                      v-model="condition.remainTotalIsZero"
                      name="qtyIsZero"
                      class="select-size-sm"
                      placeholder="请选择收款否为结清"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="结算时段"
                                 type="time"
                                 v-on:change="fromChildren($event,['addTime'])"
                                 :params="['addTime']"
                                 :value="condition.addTime"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12">
                <xy-input-button label="预计到账"
                                 type="time"
                                 v-on:change="fromChildren($event,['statementOverDay'])"
                                 :params="['statementOverDay']"
                                 :value="condition.statementOverDay"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="condition.channelName"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="销售员"
                    type="input"
                    v-on:change="fromChildren($event,['salesName','salesId'])"
                    :params="['salesName','salesId']"
                    :value="condition.salesName"
                    modalName="销售员"
                    placeholder="点击选择销售员"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="仓库"
                    type="input"
                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"
                    :params="['warehouseName','warehouseId']"
                    :value="condition.warehouseName"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:head(#)="data">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj.sync="isCheckedObj"
              :table="table"
              type="head"
              :row="data"
          />
        </template>
        <template v-slot:cell(#)="row">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj="isCheckedObj"
              :table="table"
              type="row"
              :row="row"
              :rowChecked.sync="isCheckedObj['index'+row.item.id]"
          />
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">
                <template #cell(sales_time)="data">
                  {{ toDate(data.item.sales_time) }}
                </template>

                <template #cell(order_no)="data">
                  {{ data.item.order_no }}
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <template #cell(statement_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]{{ data.item.statement_no }}
        </template>

        <!-- Columns -->
        <!--        <template #cell(id)="data">-->
        <!--          <b-link-->
        <!--              :to="{ name: 'apps-statement-edit', query: { id: data.item.id } }"-->
        <!--              class="font-weight-bold d-block text-nowrap"-->
        <!--          >-->
        <!--            #{{ data.item.id }}-->
        <!--          </b-link>-->
        <!--        </template>-->

        <!-- Column: Type -->
        <template #cell(statement_over_day)="data">
          {{ toDate(data.item.statement_over_day) }}
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(other_cost)="data">
          {{ data.item.other_cost }}
          <feather-icon icon="EditIcon" @click="showOtherCost(data.item)"/>
        </template>

        <template #cell(day)="data">
          {{ data.item.ext.day <= 0 ? '' : data.item.ext.day }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import statementUseList from './statementUseList'
import statementStore from '../statementStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import StatementCostList from "@/views/apps/statementcost/StatementCostList";
import {useToast} from "vue-toastification/composition";
import XyCheckbox from "@/views/components/xy/XyCheckbox";

export default {
  components: {
    XyCheckbox,
    StatementCostList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  directives: {
    Ripple,
  },
  props: {
    channelId: {
      type: Number,
      default: 0,
    },
    warehouseId: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
    storeId: {
      type: Number,
      default: 0,
    },
    receiveId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getChecked: function () {
      // const array = []
      // for (let i = 0; i < this.table.length; i++) {
      //   if (this.table[i].isSelect == 1) {
      //     array.push(this.table[i])
      //   }
      // }
      return this.checked
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statement/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
  },
  data() {
    return {
      fields: [
        {key: 'item_id', label: '序号'},
        {key: 'order_no', label: '编号'},
        {key: 'sales_name', label: '业务员'},
        {key: 'total_qty', label: '销售数量'},
        {key: 'total_amount', label: '销售金额'},
        {key: 'sales_time', label: '销售日期'},
      ],
      advanced_search_modal: false,
      statementId: 0,
      table: [],
      checked: [],
      allChecked: false,
      indeterminate: false,
      isCheckedObj: {},
    }
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('statement')) store.registerModule('statement', statementStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statement')) store.unregisterModule('statement')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      statementNo,addTime,

      // UI
    } = statementUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      condition,
      statementNo,addTime,

      advanced_search,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
