import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function statementreceiveitemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID'},
    { key: 'statement_no', label: '编号'},
    { key: 'relational_no', label: '关联编号'},
    // { key: 'channel_id', label: '终端客户ID', sortable: true },
    { key: 'channel_name', label: '终端客户'},
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    // { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'receivable_total', label: '应收金额'},
    { key: 'received_tatal', label: '已收金额'},
    // { key: 'other_cost', label: '其他费用'},
    { key: 'remain_total', label: '应收余额'},
    { key: 'receive_current', label: '本次收款'},
    { key: 'arrears', label: '本次收款后尚欠'},
    // { key: 'statement_over', label: '到账天数', sortable: true },
    // { key: 'statement_over_day', label: '预计到账日期', sortable: true },
    // { key: 'day', label: '账龄/天', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '结算日期'},
    // { key: 'creator', label: '创建人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const productTableColumns = [
    { key: 'warehouse', label: '仓库'},
    { key: 'code', label: '商品69码'},
    { key: 'name', label: '名称'},
    { key: 'cost', label: '含税售价'},
    { key: 'soQty', label: '售卖数量'},
    { key: 'soSubtotal', label: '售卖小计'},
    { key: 'srQty', label: '退货数量'},
    { key: 'srSubtotal', label: '退货小计'},
    { key: 'qty', label: '实际数量'},
    { key: 'Subtotal', label: '价税小计'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(true)
  const productList = ref([])
  const sum = ref({})
  const itemSum = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    productList.value.length = 0
    if (config.readonly!==0){
      for (let i = 0; i < tableColumns.length; i++) {
        if (tableColumns[i].key=="actions"){
          tableColumns.splice(i,1)
        }
      }
    }
    store
        .dispatch('statementreceiveitem/search', {
          search_key: searchKey.value,
          // start: start.value,
          // limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
          receiveId:config.receiveId,
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          productList.value = data.ext.productModelList
          sum.value = data.ext.sum
          itemSum.value = data.ext.itemSum
          listTotals.value = data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    productTableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    productList,
    sum,
    itemSum,
    // Extra Filters
  }
}
