import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {},
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/statementreceive/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/statementreceive/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/statementreceive/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/statementreceive/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    calculation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/calculation', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/changeStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveReason(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/saveReason', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    reject(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/reject', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    del(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/del', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    flush(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/flush', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    financeResourceCreateStatementReceive(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/financeResourceCreateStatementReceive', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchByFinanceId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/searchByFinanceId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    copyStatementReceive(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statementreceive/copyStatementReceive', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
